import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import Logo from '../../assets/logo_TrinityChurch-white.svg'
import './Header.css';

function Header (props) {

    const tourData = useSelector((state) => state.tours);
    const appState = useSelector((state) => state.appState);

    
    if(tourData.isTourLoading){
        return(<></>)
    }

  return(

    <Link id='logo-container'className={(appState.moduleLoading ? ' hidden' : (appState.colorTheme == 'light' ? ' drop-shadow' : null))} to="/" style={{pointerEvents:"none"}}>
        <img id='logo' className={appState.colorTheme} src={Logo} style={{pointerEvents:"none"}}/>
        <div id='logo-subtitle' className={appState.colorTheme} style={{pointerEvents:"none"}}>{tourData.currentTour ? tourData.currentTour.tourName : tourData.data.config.defaultHeader}</div>
    </Link>
  );
}

export default Header;