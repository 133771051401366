import React, { useEffect, useReducer, useRef, useState } from 'react';
import './ARWrapper.css';
import BottomNav from '../../components/BottomNav/BottomNav';
import { Outlet, useNavigate } from 'react-router';
import { useLocation, } from 'react-router-dom';
import Scan from '../../components/Scan/Scan'
import { setHotspotID, setIsScanning, setIsTourLoaded, setIsTourLoading, setIsTracking, setStopId, setStopVisited, setTourID, setIsBackDisabled, setCurrentTourUrl } from '../../reducers/ToursReducer';
import { useDispatch, useSelector } from 'react-redux';

function ARWrapper (props) {

  const [initialized, setInitialized] = useState(false);
  const tourData = useSelector((state) => state.tours)
  const showMap = useSelector((state) => state.appState.showMap)
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate();


  //Hotspot ID has changed! Redirect to the hotspot page
  useEffect(() => {
    if(tourData.currentHotspotID != null){
      if(!location.pathname.includes('hotspot/')){
        navigate("tour/"+tourData.currentStop.hrefAR + "/hotspot/"+tourData.currentHotspotID)
      }
    }
  }, [tourData.currentHotspotID])

  //Stop ID has changed! Redirect to the tour stop page
  useEffect(() => {
    if(tourData.currentStopId != null){
      if(!location.pathname.includes('tour/')){
        navigate("tour/"+tourData.currentStopId)
      }
    }
  }, [tourData.currentStopId])

  useEffect(() => {
    if(location.pathname.toLowerCase().startsWith('/ar') && !location.pathname.includes('hotspot/')){
      dispatch(setHotspotID(null));
    }
  },[location])

  useEffect(() => {
    //listen for map state change to connect 8thwall=>iFrame=>Router
    if(showMap && !location.pathname.toLowerCase().includes('map')){
      navigate(location.pathname + '/map')
    }
  }, [showMap])


  useEffect( () => {

    if(!initialized){
      dispatch(setTourID(props.tourID))
      dispatch(setCurrentTourUrl(props.src))
      setInitialized(true);
    }

  }, []);



  if(!tourData.isTourLoaded){
    return <></>
  }

  return(
    <>
      <BottomNav />
     <Scan isScanning={tourData.isScanning} isTracking={tourData.isTracking} imageTargetExample={tourData.currentStop ? tourData.currentStop.imageTargetExample : "" } />
    <Outlet/>
    </>
  );
}

export default ARWrapper;