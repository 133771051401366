
import './App.css';
import React, {useEffect, useState} from 'react'; 
import ReactGA from 'react-ga4';
import { render } from 'react-dom';
import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  redirect
} from 'react-router-dom';

import { createStore } from 'redux';
import rootReducer from './reducers/RootReducer'
import {setStopId, setData, setIsPublicSite } from './reducers/ToursReducer';
import DevPage from './routes/DevPage';
import TourIntro from './components/TourIntro/TourIntro';
import Home from './routes/Home/Home';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ARWrapper from './routes/AR/ARWrapper';
import { isMobile } from 'react-device-detect';
import useScreenOrientation from './ScreenOrientation';
import frame from './assets/Design Frames/09_stop-intro.jpg'
import IFrameContainer from './routes/AR/IFrameContainer';
import Page from './components/Page/Page';
import Tour from './components/Tour/Tour';
import Map from './components/Map/Map';
import ToursMenu from './routes/ToursMenu/ToursMenu';
import { useDispatch, useSelector, Provider } from 'react-redux';
import * as contentful from 'contentful';
import { getCMSData } from './ContentfulWrapper';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D64937',
      darker: '#FFFFFF',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

const store = createStore(rootReducer)

function App() {

  const version = "version 1.0.230c"
  const isPublicSite = document.location.href.includes("ar.trinitywallstreet.org")
  console.log("is public site? ", isPublicSite)
  ReactGA.initialize(isPublicSite ? 'G-BFZXMG6Q14' : 'G-TSVCZFV3BW');

  

  //const dispatch = useDispatch();
  let [isLoaded, setIsLoaded] = useState(false);
  const json_dir = process.env.PUBLIC_URL + "/api.json?=r"+Math.random()*999999;
  let [cmsData, setCmsData] = useState(null)
  
  const orientation = useScreenOrientation();

  const isPortrait = orientation?.includes('portrait')

  const router = createBrowserRouter(
    createRoutesFromElements(
          <Route path="/" element={<Home/>}>
            {cmsData && cmsData.tours.map((element, index) => {
              return <Route key={index} path={cmsData.tours[index].href} element={<TourIntro tourID={cmsData.tours[index].tourID[0]}  href={cmsData.tours[index].hrefAR}/>}/>
            })}
            {cmsData && cmsData.tours.map((element, index) => {
              return (
                <Route key={index} path={cmsData.tours[index].hrefAR.toLowerCase()} 
                  element={
                    <ARWrapper tourID={cmsData.tours[index].tourID[0]}  src={!isPublicSite ? cmsData.tours[index].hrefEighthWallDev : cmsData.tours[index].hrefEighthWall}/>
                  } >
                  <Route key={index+10}  path={'tour/:stopId'} loader=
                      {({ params }) => {
                        return validateStop(params.stopId.toLowerCase(), index).stopId
                      }}
                      element={<Tour />}>
                    
                      <Route key={index+100}  path={'hotspot/:hotspotID'} loader={({ params }) => {
                              validateHotspot(params.stopId, index, params.hotspotID)
                              return {stopId:params.stopId, hotspotID:params.hotspotID};
                          }} 
                          element={<Page />}
                      />

                      <Route key={index+200}  path={'map'} 
                        element={<Map />}/>
                    </Route>
              </Route> 
              
              )  
            })}
          </Route>
    )
  );

  function validateStop(stopId, index) {
    let currentStop = cmsData.tours[index].stops.find(i => i.hrefAR.toLowerCase() == stopId.toLowerCase())
    if(!currentStop){
        console.log("given direct link to stop not found: ", stopId.toLowerCase())
        throw redirect(cmsData.tours[index].href.toLowerCase())
    }
    
    return {stopId:stopId.toLowerCase(),stop: currentStop};
  }

  function validateHotspot(stopId, index, hotspotID) {
    let stopData;
    let currentHotspot;
    if(document.location.href.toLowerCase().includes("hotspot/")){
      
      stopData = validateStop(stopId.toLowerCase(), index)
      
      currentHotspot = stopData.stop.hotspots.find(i => i.hotspotId.toLowerCase() == hotspotID.toLowerCase())
      
      if(!currentHotspot){
          console.log("given direct link to hotspot not found: ", hotspotID.toLowerCase())
          throw redirect(cmsData.tours[index].href.toLowerCase())
      }
    }

    return {hotspotID:hotspotID, hotspot:currentHotspot}
  }
  

  useEffect(() => {

    if(!isLoaded){
      console.log(version);
      store.dispatch(setIsPublicSite(isPublicSite));
      fetchData();
    }
    
  }, [isLoaded]);

  function fetchData(){

      let cmsData;

      getCMSData(isPublicSite)
        .then(data => {
          if (data) {
            console.log("[Contentful cmsData]", data)
            setCmsData(data);
            setIsLoaded(true);
            
            store.dispatch(setData(data));
          }
        })
        .catch(reason => console.error("CMS Loading error caught! ", reason)); 


      // fetch(json_dir, {
      //     headers: {
      //         'Content-Type': 'application/json',
      //         'Accept': 'application/json'
      //     }
      // })
      // .then(response => response.json())
      // .then(data => {
      //   if (data) {
      //     setCmsData(data);
      //     setIsLoaded(true);
          
      //     store.dispatch(setData(data));
      //   }
      // })
      // .catch(reason => console.error("JSON Loading error caught! ", reason)); 
  }


  if(!isLoaded){
    return (<div>Loading</div>)
  }



  if(isMobile && isPortrait !== false){
  return (
    <ThemeProvider theme={theme}>
    <div className="App" role='application'>
      <Provider store={store}>
      <IFrameContainer/>

        {/* <img src={frame} style={{position:'absolute', height:'100%', width:'100%', top:0, left:0, objectFit:'contain', opacity:'.25', backgroundColor:'magenta', zIndex:'10000', pointerEvents:'none'}}/> */}
      <RouterProvider router={router}/>
      </Provider>
    </div>
    </ThemeProvider>
  );
  }
  else{
    return(
      <div className='desktop-feedback'>
        <span className='desktop-feedback-text instruction-style'
        dangerouslySetInnerHTML={{__html:!isMobile ? cmsData.config.desktopErrorMsg
        : cmsData.config.landscapeErrorMsg}}
        />
          
      </div>
    )
  }
}

export default App;
