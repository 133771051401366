import React, { useEffect } from 'react';
import './Map.css';

function MapHotspot (props) {


 const handleClick = (event) => {
    if(props.clickCallback) props.clickCallback(props.id);
 }

 if(props.active){

      return( 
      
        <div style={props.style} className="map-hotspot pulse">
            <img onClick={handleClick} src={"/AR/images/icn_stop-selected.png"} />
            <span className='map-hotspot-number'>{props.index}</span>
            <div className="hit" onClick={handleClick}/>
            <img className="you-are-here" src={"/AR/images/churchyard_map_YouAreHere.png"}/>
        </div>
    );
 }

  return(
    
        <div style={props.style} className="map-hotspot">
            <img src={props.selected ? "/AR/images/icn_stop-selected.png" : "/AR/images/icn_stop-active.png"} />
            <span className='map-hotspot-number'>{props.index}</span>
            <div className="hit" onClick={handleClick}/>
        </div>

  );
}

export default MapHotspot;