import {useState, useEffect} from 'react'

let ScreenOrientation = window.screen.orientation

const getOrientation = () =>
  ScreenOrientation?.type

const useScreenOrientation = () => {
  const [orientation, setOrientation] =
    useState(getOrientation())

  const updateOrientation = event => {
    console.log("Orientation updated!")
    setOrientation(getOrientation())
  }

  useEffect(() => {

    ScreenOrientation?.removeEventListener(
      'change',
      updateOrientation
    );

    ScreenOrientation?.addEventListener(
      'change',
      updateOrientation
    )
    return () => {
      ScreenOrientation?.removeEventListener(
        'change',
        updateOrientation
      )
    }
  }, [])

  return orientation
}

export default useScreenOrientation