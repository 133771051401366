import React from 'react';
import { Link } from 'react-router-dom';
import './TourButton.css'

function TourButton (props) {

  const data = `${props.title} <span class="blockout-style blockout-subtitle">${props.subtitle}</span>`
  return(
    <React.Fragment>
        <div className="tour-button-wrapper" onClick={props.onClick}>      
        <Link to={props.href} >
            <img className="tour-button-image" src={props.imageSrc}/>
            <span className="blockout-style blockout-title" dangerouslySetInnerHTML={{__html:data}}>
                

            </span>
            </Link>
        </div>

    </React.Fragment>
  );
}

export default TourButton;