import React, { useEffect, useState, useRef } from 'react';
import './Map.css';
import { Button, useStepContext } from '@mui/material';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useDispatch, useSelector } from 'react-redux';
import MapHotspot from './MapHotspot';
import { setIsScanning, setIsTracking, setStopId, setIsBackDisabled } from '../../reducers/ToursReducer';
import { setShowMap } from '../../reducers/AppStateReducer';
import { useLocation, useNavigate, } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { create } from '@mui/material/styles/createTransitions';

function Map (props) {

  const tourData = useSelector((state) => state.tours)
  const appState = useSelector((state) => state.appState)
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate();
  const showMap = useSelector((state) => state.appState.showMap)

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [progress, setProgress] = useState(0);
  const [stopList, setStopList] = useState(null);
  const [selectedStop, setSelectedStop] = useState(null)
  const pinchZoomWrapper = useRef(null);
  let index = 0;

  const handleClose = () => {
    navigate('/ar/' + tourData.currentTour.tourID[0] + '/tour/' + tourData.currentStop.hrefAR)

    dispatch(setIsScanning(true))
    dispatch(setShowMap(false))
  }

  const handleHotspotClick = (id) => {
    setSelectedStop(id)
  }

  const handleHotspotLoad = (event) => {
   
  }

  const handleImageLoaded = (event) => {
    
    setImageWidth(event.target.clientWidth);
    setImageHeight(event.target.clientHeight);
    setProgress(1);
    //pinchZoomWrapper.current.centerView(1)
  }

  
  useEffect(() => {
    if(location.pathname.toLowerCase().includes('/map')){
      dispatch(setShowMap(true));
      
    }
  },[location])

  useEffect(() => {
    console.log(tourData.currentStopId)
    setSelectedStop(tourData.currentStopId)
  }, [tourData.currentStopId])

  useEffect(() => {
    createList();
  }, [tourData.data])

  const createList  = () => {
    let list = [];

    tourData.data.tours.slice(0).map(tour => {
      tour.stops.slice(0).map(stop => {
        list.push(stop)
      })
    });

    setStopList(list);
  }


  if(!stopList || !appState.showMap) {
    return <></>
  }
  else{

    return(
          <div className="mapContainer">
            <div className="relativeMapContainer">
                {/* <TransformWrapper ref={pinchZoomWrapper} limitToBounds={true} initialScale={1} maxScale={3} centerOnInit={true} > */}
                    <div className={'map' + (progress < 1 ? ' hidden' : "")}>
                        {/* <TransformComponent> */}
                            <div className="map-image">
                              <img src={tourData.data.config.mapImage} onLoad={handleImageLoaded}/>
                              <div className="hotspot-container" style={{width:imageWidth, height:imageHeight}}>
                                  {imageWidth && imageHeight  && 
                                    stopList.map(element => {
                                      return <MapHotspot 
                                          index={++index}
                                          key={index}
                                          onLoad={handleHotspotLoad} 
                                          selected={element.stopId === selectedStop}
                                          active={tourData.currentStopId === element.stopId} 
                                          style={{left:element.mapX * imageWidth, top:element.mapY * imageHeight}} 
                                          id={element.stopId} 
                                          clickCallback={handleHotspotClick} 

                                          />
                                    })
                                  }
                              </div>
                            </div>
                        {/* </TransformComponent> */}
                
                    </div>      
                {/* </TransformWrapper>  */}
                {/* <span className={'map-instructions' + (progress < 1 ? ' hidden' : "")}>{tourData.currentTour.complete ? tourData.currentTour.mapCompleteInstructions : tourData.currentTour.mapInstructions}</span>  */}
                <ol className={'map-list' + (progress < 1 ? ' hidden' : "")}>
                  {stopList.map((stop => {
                    return <li 
                      key={stop.mapTitle}
                      style={selectedStop === stop.stopId || ( selectedStop == null && tourData.currentStopId === stop.stopId) ?
                        {color:"#94BC42", textDecoration:"underline"} : null}
                      onClick={() => handleHotspotClick(stop.stopId)}>
                        {stop.mapTitle}
                      </li>
                }))}
                </ol>
              </div>
              {progress < 1 && <div className='loaderOverlay'><PulseLoader color="#FFFFFF"/></div>}
              <div className="buttonBar">
              <Button className="button" variant="contained" onClick={handleClose}>Close</Button>
              </div>
          
          </div>

    );
  }
}

export default Map;