import React, { useEffect } from 'react';
import './BottomNav.css';
import { Button } from '@mui/material';

import scanIcon from './icons/scan-icon.png';
import mapIcon from './icons/map-icon.png';
import toursIcon from './icons/tours-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { setIsScanning, setIsTracking } from '../../reducers/ToursReducer';
import { setShowMap, setShowMenu } from '../../reducers/AppStateReducer';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import { TrackGAEvent } from '../Analytics/Analytics';

function BottomNav (props) {

  const tourData = useSelector((state) => state.tours)
  const appState = useSelector((state) => state.appState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let location = useLocation();

  const handleScan = () => {
    
    dispatch(setIsScanning(false));
     dispatch(setIsTracking(false));
  }

  const handleMap = () => {
    TrackGAEvent("custom_event", "View_Map", location.pathname)

    dispatch(setIsScanning(false));
    dispatch(setShowMap(true));
  }

  // const handleTours = () => {
  //   dispatch(setShowMenu(true))
  //   navigate('menu')
  // }

  if(!tourData.isTourLoaded || appState.showMap || appState.showMenu || tourData.currentHotspot || !location.pathname.toLowerCase().startsWith("/ar")){
    return <></>
  }

  return(
    <div>
      <div className="bottom-nav">
          <Button className="nav-button" variant="contained" onClick={handleScan} disabled={!tourData.isScanning} startIcon={<img src={scanIcon} />}>Scan Marker</Button>
          <div className="spacer"></div>
          <Button className="nav-button" variant="contained" onClick={handleMap} disabled={appState.showMap} startIcon={<img src={mapIcon} />}>View Map</Button>
          {/* <div className="spacer"/>
          <Button className="nav-button" variant="contained" onClick={handleTours} startIcon={<img src={toursIcon} />}>Main Menu</Button> */}
      </div>
    </div>
  );
}

export default BottomNav;