import ReactGA from "react-ga4";



export const TrackGAEvent = (category, event_name, label, data) => {
    console.log("GA Event: ", category, ":", event_name, ":", label);
    let event_params = {
      category, label, ...data
    };
    
    //Send GA4 Event
    ReactGA.event(event_name, event_params);
};