import { act } from "react-dom/test-utils";

const SET_DATA = 'SET_DATA';
const SET_IS_PUBLIC_SITE = 'SET_IS_PUBLIC_SITE';
const SET_HOTSPOT_ID = 'SET_HOTSPOT_ID';
const SET_STOP_ID = 'SET_STOP_ID';
const SET_TOUR_ID = 'SET_TOUR_ID';
const SET_STOP_VISITED = 'SET_STOP_VISITED';
const SET_IS_SCANNING = 'SET_IS_SCANNING';
const SET_IS_TRACKING = 'SET_IS_TRACKING';
const SET_IS_BACK_DISABLED = 'SET_IS_BACK_DISABLED';
const SET_IS_TOUR_LOADING = 'SET_IS_TOUR_LOADING';
const SET_IS_TOUR_LOADED = 'SET_IS_TOUR_LOADED';
const SET_CURRENT_TOUR_URL = 'SET_CURRENT_TOUR_URL';
const SET_SECONDARY_BUTTON_PRESSED = 'SET_SECONDARY_BUTTON_PRESSED';


export const setData = (data) => {
    return {
      type: SET_DATA,
      payload: data
    };
  };

export const setIsPublicSite = (isPublic) => {
  return {
    type: SET_IS_PUBLIC_SITE,
    payload:isPublic
  }
}


export const setHotspotID = (data) => {
    return {
      type: SET_HOTSPOT_ID,
      payload: data
    };
  };

  export const setIsTourLoading = (data) => {
    return {
      type: SET_IS_TOUR_LOADING,
      payload: data
    };
  };

  export const setIsTracking = (data) => {
    return {
      type: SET_IS_TRACKING,
      payload: data
    };
  };

  export const setIsBackDisabled = (data) => {
    return { 
      type: SET_IS_BACK_DISABLED,
      payload:data
    }
  }

  export const setStopVisited = (data) => {
    return {
      type: SET_STOP_VISITED,
      payload: data
    };
  };

  export const setIsTourLoaded = (data) => {
    return {
      type: SET_IS_TOUR_LOADED,
      payload: data
    };
  };

  
  export const setTourID = (data) => {
    return {
      type: SET_TOUR_ID,
      payload: data
    };
  };

  
  export const setStopId = (data) => {
    return {
      type: SET_STOP_ID,
      payload: data
    };
  };
  
  export const setIsScanning = (data) => {
    return {
      type: SET_IS_SCANNING,
      payload: data
    };
  }

  export const setCurrentTourUrl = (data) => {
    return {
      type: SET_CURRENT_TOUR_URL,
      payload: data
    };
  }

  export const setSecondaryButtonPressed = (data) => {
    return {
      type: SET_SECONDARY_BUTTON_PRESSED,
      payload: data
    };
  }

  
const defaultState = {
    data:null,
    isPublic:false,
    currentTourUrl:null,
    currentTourID:null,
    currentTour:null,
    currentTourState:[],
    currentStopId:null,
    currentStop:null,
    currentHotspotID:null,
    currentHotspot:null,
    secondaryButtonPressed:false,
    isScanning:true,
    isTourLoaded:false,
    isTourLoading:false,
    isBackDisabled:true,
    isTracking:false,
    tourStates:[]
}



const toursReducer = (state = defaultState, action) => {

    switch (action.type)
    {
        case SET_DATA:
            state.data = action.payload;
            return { ...state };
        case SET_IS_PUBLIC_SITE:
            state.isPublic = action.payload;
            return { ...state };
        case SET_IS_TRACKING:
            state.isTracking = action.payload;
            return { ...state };
        case SET_CURRENT_TOUR_URL:
            state.currentTourUrl = action.payload;
            return { ...state };
        case SET_IS_TOUR_LOADED:
            state.isTourLoaded = action.payload;
            return { ...state}
        case SET_IS_TOUR_LOADING:
              state.isTourLoading = action.payload;
              return { ...state}
       case SET_IS_SCANNING:
              state.isScanning = action.payload;
              if(action.payload == false){
                state.secondaryButtonPressed = false;
              }
              return { ...state };
        case SET_IS_BACK_DISABLED:
              state.isBackDisabled = action.payload;
              return {...state};
        case SET_SECONDARY_BUTTON_PRESSED:
              state.secondaryButtonPressed = action.payload;
              return { ...state };
        case SET_TOUR_ID:
            state.currentTourUrl = null;
            state.currentTourID = action.payload;
            //state.currentStopId = null;
            state.currentHotspotID = null;
            state.currentHotspot = null;
            var tour = state.data.tours.find(i => i.tourID[0] === action.payload);
            if(!tour){
              state.currentTour = null;
              state.currentTourState = null;
              return { ...state };
            }
            
            state.currentTour = tour;
            state.currentTourState = state.tourStates[tour.tourID[0]];
            let states = state.tourStates;
            if(!states[state.currentTourID]){
                let tourStatesDefault = []
                const tour = state.data.tours.find(i => i.tourID[0] == state.currentTourID);
                if(tour){
                  const stops = tour.stops;
                  stops.forEach(stop => {
                    tourStatesDefault.push({stopId: stop.stopId, x:stop.mapX, y:stop.mapY, visited:false})
                  });
                  states[state.currentTourID] = tourStatesDefault;
                  state.tourStates = states;
                }
            }
            return { ...state };
        
        case SET_STOP_ID:
            state.currentHotspotID = null;
            state.currentStopId = action.payload;
            if(state.currentTour && action.payload){
              state.currentStop = state.currentTour.stops.find(i => i.hrefAR.toLowerCase() == action.payload.toLowerCase())
              if(!state.currentStop)
                console.error("Could not set stop from:", action.payload)

              state.currentStopId = state.currentStop.stopId
            }
            else{
              console.log("CURRENT TOUR IS NOT DEFINED")
            } 

            console.log("state.currentStop", state.currentStop)
            return { ...state };
        case SET_HOTSPOT_ID:
            state.currentHotspotID = action.payload;
            if(action.payload == null){
              state.currentHotspot = null;
            }
            else{
              
              state.currentHotspot = state.currentStop.hotspots.find(i => i.hotspotId.toLowerCase() == action.payload.toLowerCase())
              state.currentHotspotIndex = state.currentStop.hotspots.indexOf(state.currentHotspot)
            }

            return { ...state };
        default:
            return state;

    }
}

export default toursReducer;
   