import React, {useEffect} from 'react';
import { Link, Outlet } from 'react-router-dom';
import './Home.css'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import ToursMenu from '../ToursMenu/ToursMenu';
import { setColorTheme} from '../../reducers/AppStateReducer';
import BottomNav from '../../components/BottomNav/BottomNav';

function Home(props) {

  const tourData = useSelector((state) => state.tours);
  const appState = useSelector((state) => state.appState);
  const dispatch = useDispatch();

  useEffect(() => {
    
    if(tourData.isTourLoaded && !appState.showMenu && !tourData.currentHotspot){
        dispatch(setColorTheme('light'));
    }else{
        dispatch(setColorTheme('dark'));
    }
  },[tourData.isTourLoaded, appState.pageContent, appState.showMenu, tourData.currentHotspot])


  return (<React.Fragment>

        <Header/>
        <div className='links'>
        {tourData.data.config.links.map((link, index) => {
            return <Link key={index} className='bottom-nav-link' target="_blank" to={link.fields.link} >{link.fields.linkText}</Link>
          })
        }
        </div>
        
        

        <ToursMenu/>
        <Outlet/>
        {/* {tourData.currentHotspot ? <Page pageContent={appState.pageContent}/> : null} */}

        {/* <Map active={appState.showMap} closeCallback={handleMapClose}/> */}
  

  </React.Fragment>);
}


export default Home;