
const SET_COLOR_THEME = 'SET_COLOR_THEME'
const SET_SHOW_MENU = 'SET_SHOW_MENU'
const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT'
const SET_SHOW_MAP = 'SET_SHOW_MAP'



export const setColorTheme = (data) => {
    return {
      type: SET_COLOR_THEME,
      payload: data
    };
  };

  export const setPageContent = (data) => {
    return {
      type: SET_PAGE_CONTENT,
      payload: data
    };
  };

  export const setShowMenu = (data) => {
    return {
      type: SET_SHOW_MENU,
      payload: data
    };
  };

  export const setShowMap = (data) => {
    return {
      type: SET_SHOW_MAP,
      payload: data
    };
  };

  
const defaultState = {
    showMenu:false,
    showMap:false,
    pageContent:null,
    colorTheme:'dark'
}


const appStateReducer = (state = defaultState, action) => {

    switch (action.type)
    {
          case SET_PAGE_CONTENT:
            state.pageContent = action.payload;
          return { ...state };

          case SET_COLOR_THEME:
            state.colorTheme = action.payload;
          return { ...state };

          case SET_SHOW_MENU:
            state.showMenu = action.payload;
          return { ...state };

          case SET_SHOW_MAP:
            state.showMap = action.payload;
          return { ...state };

          default:
            return state;

    }
}

export default appStateReducer;
   