import React, { useEffect, useRef, useState } from 'react';
import './GlobalButton.css';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';

function GlobalButton (props) {

  return(
    <Button className={"global-button " + props.className} fullWidth={props.fullWidth} variant="contained" style={props.style} onClick={props.onClick} disabled={props.disabled ? props.disabled : false} startIcon={props.icon?<img src={props.icon} className="icon" />:null}>{props.innerText}</Button>
  );
}

export default GlobalButton;