import React, { useEffect, useRef, useState } from 'react';
import './ImageCarousel.css'
import placeholderImage from '../../assets/img_FPO_1-1_black.png'
import carouselArrow from '../../assets/btn_carousel-arrow.svg'
import magIcon from '../../assets/icn_magnifying-glass.svg'
import { PulseLoader } from 'react-spinners';

function ImageCarousel(props){
  
  let [slides, setSlides] = useState(null);
  let [slideIndex, setSlideIndex] = useState(0);
  let [arrowShowing, setArrowShowing] = useState(false);
  let [progress, setProgress] = useState(false);


  useEffect(() => {
    setData(props.hotspot)
  }, [props.hotspot])

  

  useEffect(() => { 
    setProgress(false);
  },[props.hotspot.images])

  useEffect(() => {
    if(!slides) return;

    //once slides and dots are initalized.. do this. Shouldn't be called more than once

    // for (let i = 0; i < document.getElementsByClassName('dot').length; i++) {
    //   const d = document.getElementsByClassName('dot')[i]
    //   if (props.hotspot.images.length <= 1 || i >= props.hotspot.images.length) {
    //     d.style.display = 'none'
    //   } else {
    //     d.style.display = ''
    //   }
    // }
    if (props.hotspot.images.length <= 1) {
      document.getElementById('prevButton').style.display = 'none'
      document.getElementById('nextButton').style.display = 'none'
    } else {
      document.getElementById('prevButton').style.display = ''
      document.getElementById('nextButton').style.display = ''
    }


    
    setSlideIndex(1)
  }
,[slides]);

  useEffect(() => {
    updateCarousel()
  },[slideIndex])


    function setData(hotspot) {
      
        if(!hotspot) return

        const docSlides = document.getElementsByClassName('mySlides')

        const images = document.getElementsByClassName('img')
        const usedSlides = []
    
        for (let i = 0; i < images.length; i++) {
          if (props.hotspot.images.length > i) {
            images[i].src = process.env.PUBLIC_URL + props.hotspot.images[i].image
            usedSlides.push(docSlides[i])
          } else {
            images[i].src = ''
          }
        }
        setSlides(usedSlides)
    }

    function updateCarousel(){
      
        props.onPaginationIndexChange(slideIndex)

        if(!slides || slides.length <= 0) return;
        let i
    
       
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = 'none'
        }
       
        slides[slideIndex - 1].style.display = 'inline-block'
    }
    
    function plusSlides(n){

        let newSlideIndex = slideIndex + n
        if (newSlideIndex > slides.length) {
          setSlideIndex(1)
        }
        else if (newSlideIndex < 1) {
          setSlideIndex(slides.length)
        }
        else {
          setSlideIndex(newSlideIndex)
        }
    }
    
      function currentSlide(n){
        if (n + 1 > slides.length) {
          setSlideIndex(1)
        }
        else{
          setSlideIndex(n+1)
        }
      }
    
      return(
        <React.Fragment>
        <div className="slideshow-container">
          {props.hotspot.images.map((image, index) => {
            return (
              <div key={index} className="mySlides ">
                <img src={placeholderImage} onClick={props.onHotspotImageSelected} onLoad={() => {if(index==0) setProgress(true)}} className="img"/>
                <img className='mag-icon' src={magIcon}/>
                {!progress && <div className='loaderOverlay'><PulseLoader color="#FFFFFF"/></div>}
              </div>
          )})}
          <img className="prev" id="prevButton" src={carouselArrow} onClick={() => {plusSlides(-1)}}/>
          <img className="next" id="nextButton" src={carouselArrow} onClick={() => {plusSlides(1)}}/>

        </div>
        <div id='dot-container'>
          {props.hotspot.images.length > 1 && props.hotspot.images.map((image, index) => {
              return <span key={index} className={slideIndex -1 == index ? "dot active" : "dot"} onClick={() => {currentSlide(index)}}></span> 
          })}
        </div>
            
        </React.Fragment>
      );
}


export default ImageCarousel;