import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import frame from '../../assets/Design\ Frames/11_tours-menu.jpg'
import {setIsBackDisabled, setTourID} from '../../reducers/ToursReducer';
import TourButton from './TourButton';
import './ToursMenu.css'
import { setShowMenu } from '../../reducers/AppStateReducer';
import GlobalButton from '../../components/global/GlobalButton';
import { Button } from '@mui/material';

function ToursMenu(props) {
  const tourData = useSelector((state) => state.tours)
  const appState = useSelector((state) => state.appState)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [isOverlayMenu, setIsOverlayMenu] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if(location.pathname == "/"){
      dispatch(setShowMenu(true));
      setIsOverlayMenu(false);
    }
    else if(location.pathname.toLowerCase().includes('menu')){
      setIsOverlayMenu(true);
      dispatch(setShowMenu(true))
    }
  },[location]);

  useEffect(() => {

    function cleanUp(){
      dispatch(setShowMenu(false));
    }
    return cleanUp;
  },[])
  
  function getTourButtons(){
    if(!tourData) return null;

    var buttons = []

    tourData.data.tours.forEach((tour) => {
      buttons.push(getTourButton(tour));
    });
    return buttons;
  }

  // const handleClose = () => {
  //     dispatch(setShowMenu(false));
  //     navigate(props.parentHREF)
  // }

  function getTourButton(tourData){
        return <TourButton 
          key={tourData.tourTitle}
          imageSrc={tourData.tourImage} 
          title={tourData.tourTitle} 
          subtitle={tourData.tourSubtitle} 
          href={tourData.href}
          tourID={tourData.tourID}/>
          
        
  }

  if(!appState.showMenu)return <></>

  return(
    <React.Fragment>

      <div className="tour-menu">
        <div className='page-wrapper'>
        <span className="blockout-style" dangerouslySetInnerHTML={{__html:tourData.data.config.tourMenuTitleText}}></span>
        <p className="paragraph-style">{tourData.data.tourMenuIntroductionText}</p>
        <p className="instruction-style">{tourData.data.tourMenuInstructionText}</p>
        {getTourButtons()}
        <br/>
        <br/>
        <br/>
        <br/>
      </div>

        {/* {
        tourData.currentTour && isOverlayMenu && 
        <div className="bottom-nav">
          <Button className="nav-button" variant="contained" onClick={handleClose}>Close</Button>
        </div>
        } */}
      </div>

    </React.Fragment>
  );

}

export default ToursMenu;