import React from 'react';
import './Scan.css';
import { Button } from '@mui/material';
import { setIsScanning, setSecondaryButtonPressed } from '../../reducers/ToursReducer';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedArrow from '../AnimatedArrow/AnimatedArrow';

function Scan (props) {

  const tourData = useSelector((state) => state.tours)
  const appState = useSelector((state) => state.appState)
  const dispatch = useDispatch()

  function handleScanButtonClicked(){
      dispatch(setIsScanning(true));
  }

  // function handleSecondaryMarkerButtonClicked(){
  //   dispatch(setSecondaryButtonPressed(true));
  // }

  if((tourData.isScanning && tourData.isTracking) || appState.showMap){
    if(tourData.secondaryButtonPressed)  
      dispatch(setSecondaryButtonPressed(false));
    return <></>
  }


  // if(!tourData.currentStopId){//user is required to select a stop before continuing. If there's only one stop it is auto selected.
  //   return(
  //     <React.Fragment>
  //     <div className='scan-container black'>
  //     <div className='scan-overlay'>
  //         <span className='scan-instructions scanning'>Select a stop on the map to begin the&nbsp;tour</span>
  //     </div>
  //     <div className="arrow-container">
  //       <AnimatedArrow rotationDeg={180}/>
  //     </div>
  //     </div>
  //   </React.Fragment>
  //   );
  // }

  let imageTarget = props.imageTargetExample;
  
  if(tourData.isScanning) {
    return(
      <React.Fragment>
      <div className='scan-container'>
      <div className='scan-overlay'>
          <span className='scan-instructions scanning'>
            {tourData.currentTour.scanInstructions}
          </span>
          <svg width="100%" height="100%" viewBox="0 0 100 500" preserveAspectRatio="xMinYMid slice">     
              <image href={process.env.PUBLIC_URL + imageTarget} className="scan-marker-image-overlay" 
              width="80" height="80" rx="5" ry="5"  x="10" y="200"/>
              <defs>
                <mask id="Mask">
                  <rect rx="0" ry="0" width="100" height="500" fill="white"/>
                  <rect rx="5" ry="5"  x="10" y="200" width="80" height="80" fill="black"/>
                </mask>
              </defs>
              <rect x="0" y="0" width="100" height="500" fill="#000000AA" mask="url(#Mask)"/>
              <rect rx="5" ry="5"  x="10" y="200" width="80" height="80" stroke="white" strokeWidth="0.3" fillOpacity="0"/>
          </svg>

      </div>
      
    </div>
    </React.Fragment>
    );
  }

  return(
    <React.Fragment>
      <div className='scan-container black'>
        <div className='scan-center'>
            <div className='scan-instructions'>{tourData.currentTour.findInstructions}</div>
            <img src={process.env.PUBLIC_URL + props.imageTargetExample} className="scan-marker-image"/>
            <Button className="nav-button" variant="contained" onClick={handleScanButtonClicked}>Continue</Button>
        </div>
      </div>
    </React.Fragment>
    
  );
}

export default Scan;