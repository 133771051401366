import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { Button } from '@mui/material';
import PinchZoom from '../PinchZoom/PinchZoom';
import {  setStopId } from '../../reducers/ToursReducer';
import ReactPlayer from 'react-player'
import { useLoaderData, useNavigate, redirect, Outlet } from 'react-router';
import ReactGA from "react-ga4";

function Tour(props){
    
    const [isPinchZoomOpen, setIsPinchZoomOpen] = useState(false)
    const [paginationIndex, setPaginationIndex] = useState(0)
    const [currentHotspot, setCurrentHotspot] = useState(undefined)
    const tourData = useSelector((state) => state.tours)
    const containerRef = useRef(null);
    const idFromURL = useLoaderData();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    
    useEffect(() => {
        //console.log(tourData.currentStop, idFromURL)
        if(!tourData.currentStop){
            console.log("Setting stop id!", idFromURL)
            dispatch(setStopId(idFromURL));
        }
      }, [idFromURL])

    return (
        <>
            <Outlet/>
        </>

    );
    
}



export default Tour 