import React, {useEffect, useState, useRef} from 'react'; 

import './PinchZoom.css';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';
import GlobalButton from '../global/GlobalButton';
import PinchZoomHand from '../../assets/hand_pinch-zoom.png'
import { PulseLoader } from 'react-spinners';

function PinchZoom(props) {
    const [hasPinchStarted, setHasPinchStarted] = useState(false)
    const [progress, setProgress] = useState(0);
    const pinchZoomWrapper = useRef(null);

    const handlePinchStart = () => {
      if(!hasPinchStarted)
      {
        setHasPinchStarted(true);
        props.onPinchStart();
      }
    }

    const handleLoaded = () => {
      setProgress(1);
      pinchZoomWrapper.current.centerView(1)
    }

    useEffect(() => { 
      setProgress(0);
    },[props.image.pinchZoomImage])

    if(!props) return null;
    return(
      <React.Fragment>
        <TransformWrapper ref={pinchZoomWrapper} limitToBounds={true} initialScale={1} maxScale={3} onZoomStart={handlePinchStart} centerOnInit={true}>
          <div id='pinch-zoom-container'>
              <div id='pinch-zoom-text-container'>
                <span id='pinch-zoom-body-text' className='body-text'>{props.image.captionBodyText}</span>
              </div>
              <TransformComponent >
                <div id='pinch-zoom-area'> 
                    <img id='pinch-zoom-image' onLoad={handleLoaded} src={props.image.pinchZoomImage ? props.image.pinchZoomImage : props.image.image}/>
                    {!hasPinchStarted?<img id='pinch-zoom-hand' src={PinchZoomHand}/>:null}
                </div>
              </TransformComponent>
              {progress < 1 && <div className='loaderOverlay'><PulseLoader color="#FFFFFF"/></div>}
              <GlobalButton onClick={props.onClose} className='close-button' innerText="Close"/>
          </div>
          </TransformWrapper>
      
      </React.Fragment>
    );
  
}

export default PinchZoom;