import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { setCurrentTourUrl, setHotspotID, setIsScanning, setIsTourLoaded, setIsTourLoading, setIsTracking, setStopId } from '../../reducers/ToursReducer';
import { setShowMap } from '../../reducers/AppStateReducer';
import { TrackGAEvent } from '../../components/Analytics/Analytics';


function IFrameContainer (props) {

    const [targetWindow, setTargetWindow] = useState(false);
    const tourData = useSelector((state) => state.tours)
    const dispatch = useDispatch();
    const tourDataRef = useRef();
    

  const handleLoaded = (event) => {
      console.log(event.target.contentWindow);
      setTargetWindow(event.target.contentWindow);
    }

    function handleMessage (event) {

      if(event.data.event && event.data.target === 'shell'){ 
        processMessage(event.data);
      }
    }

    const postMessage = (event, data) => {
      if(!targetWindow) return;
      targetWindow.postMessage({event:event, data:data, target:"module"}, 'https://trinitywallstreet.8thwall.app');
    }

      
  const getNextStopId = () => {

    if(!tourData.currentStop || !tourData.currentTourID) {
      return null;
    }

    const tourState = tourData.tourStates[tourData.currentTourID]
    const currentStop = tourData.currentStop;
    const notVisitedStops = tourState.filter(i => i.stopId != tourData.currentStopId);
    console.log(notVisitedStops)
    if(notVisitedStops.length == 0) {
      return null;
    }

    notVisitedStops.sort(function(a, b) {
      return getStopDistance(currentStop.mapX, currentStop.mapY, a.x, a.y) - getStopDistance(currentStop.mapX, currentStop.mapY, b.x, b.y);
    });
    return notVisitedStops[0].stopId;
  }

  const getStopDistance = (x1, y1, x2, y2) => {
    let x = x2-x1;
    let y = y2-y1;
    return Math.sqrt(x*x+y*y);
  }

  const getStopData = () => {
    const stopId = tourData.currentStopId;
    const nextStop = getNextStopId();
    return {stopId:stopId, nextStopId:nextStop}
  }

  useEffect( () => {
    postMessage("loadStop",getStopData());
    console.log("Stop changed! loading...", tourData)
    tourDataRef.current = tourData;
  }, [tourData.currentStopId]);
  
  useEffect( () => {
    if(tourData.currentHotspotID == null)
      postMessage("scanState", tourData.isScanning)
  }, [tourData.currentHotspotID])

  useEffect( () => {
    postMessage("scanState",tourData.isScanning);
  }, [tourData.isScanning]);

  function processMessage(message) {
    console.log(tourData)
      switch(message.event){
        case "loaded":
          dispatch(setIsTourLoading(false));
          dispatch(setIsTourLoaded(true));
          postMessage("scanState",tourData.isScanning);
          break;
        case "tracking":
          TrackGAEvent("custom_event", "Scan_Marker", tourDataRef.current.currentStopId)
          dispatch(setIsTracking(true));
          break;
        case "hotspot":
          var hotspotID = message.data;
          var stop = tourDataRef.current.currentStop;
          if(!stop) return;
          var hotspot = stop.hotspots.find((o) => {return o.hotspotId == hotspotID});
          if(!hotspot) return;
          dispatch(setHotspotID(hotspotID))
          break;
        case "nextStop":
          TrackGAEvent("custom_event", "Next_Stop", message.data)
          dispatch(setStopId(message.data));
          dispatch(setShowMap(true));
          dispatch(setIsTracking(false))
          dispatch(setIsScanning(true))
          break; 
      }
  }
    
  useEffect(() => {
    
    
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);

  },[targetWindow]);

  useEffect( () => {

    if(tourData.currentTourUrl){
        dispatch(setIsTourLoading(true));
    }
  }, [tourData.currentTourUrl]);

  //////////////

  if(!tourData.currentTourUrl){
      return <></>
  }
  return(
      <div id='ar-container' className='AR-Container'>
          <iframe onLoad={handleLoaded} src={tourData.currentTourUrl} allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;" />
      </div>
  );
}

export default IFrameContainer;